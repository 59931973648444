<template>
  <div>
    <feed-list v-show="state === states.list" @manageFeedMasks="manageFeedMasks" />
    <feed-masks v-if="state === states.masks" :feed="feed" @closePage="closePage" />
  </div>
</template>

<script>
const FeedList = () => import('@/components/admin/FeedList.vue');
const FeedMasks = () => import('@/components/admin/FeedMasks.vue');

export default {
  name: 'Feeds',
  components: {
    FeedList,
    FeedMasks,
  },
  data() {
    return {
      state: 0,
      states: Object.freeze({
        list: 0,
        masks: 1,
      }),
      feed: null,
    };
  },
  methods: {
    manageFeedMasks(feed) {
      this.feed = feed;
      this.state = this.states.masks;
    },
    closePage() {
      this.feed = null;
      this.state = this.states.list;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
